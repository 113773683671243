import React from 'react';

const PublishedJobTableRow = ({ published_job_page }) => {
  return (
    <tr>
        <td className="py-3 px-5 border-b border-blue-gray-50">
            <div className="flex items-center gap-4">
                <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-bold">
                {published_job_page.address}
                </p>
            </div>
        </td>        
      <td className="py-3 px-5 border-b border-blue-gray-50">
        <div className="flex items-center gap-4">
          <a href={`/published_job_pages/${published_job_page.id}`}>
            <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-bold">
              {published_job_page.title}
            </p>
          </a>
        </div>
      </td>
      <td className="py-3 px-5 border-b border-blue-gray-50">
        <a href={published_job_page.slug}>
          <p className="block antialiased font-sans text-xs font-medium text-blue-gray-600">
            {published_job_page.slug}
          </p>
        </a>
      </td>
      <td className="py-3 px-5 border-b border-blue-gray-50">
        <p className="block antialiased font-sans text-xs font-medium text-blue-gray-600">
        {published_job_page.target_keywords}
        </p>
      </td>      
      <td className="py-3 px-5 border-b border-blue-gray-50">
        <div className="w-10/12">
          <p className="antialiased font-sans mb-1 block text-xs font-medium text-blue-gray-600">
            {published_job_page.views}
          </p>
          <div className="flex flex-start bg-blue-gray-50 overflow-hidden w-full rounded-sm font-sans text-xs font-medium h-1">
            <div
              className="flex justify-center items-center h-full bg-gradient-to-tr from-blue-600 to-blue-400 text-white"
              style={{ width: '60%' }}
            ></div>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default PublishedJobTableRow;