import React, { Component } from "react";
import SearchField from "../shared/SearchField";

class TableTop extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="d-flex bd-highlight">
                <div className="p-2 bd-highlight"> { this._newLink() } </div>
                <div className="p-2 bd-highlight"> { this._inviteLink() } </div>
                {/* <div className="p-2 bd-highlight"> { this._groupLink() } </div> */}
                <div className="p-2 bd-highlight flex-grow-1"> <SearchField action="/employees" search="first_name_or_last_name_or_email_or_group_name_cont" /> </div>
            </div>
        );
    }

    _groupLink() {
        const { displayGroup } = this.props;

        if( displayGroup ) {
            return(<a href="groups" title="Manage groups."><i className="fas fa-users-cog" /></a>);

        } else {
            return null;
        }
    }

    _inviteLink() {
        const { permissions } = this.props;

        if( permissions["create"] ) {
            return(
                <a href="employees/invitation/new" title="Invite employee."><i className="fas fa-paper-plane" /></a>
            );
        } else {
            return null;
        }
    }

    _newLink(){
        const { permissions } = this.props;

        if( permissions["create"] ) {
            return(
                <a href="employees/new" title="Add employee."><i className="fas fa-plus" /></a>
            );
        } else {
            return null;
        }        
    }
}

export default TableTop;
