import React, {Component} from "react";

import LineItemDesktop from "./Desktop/LineItem";
import LineItemMobile from "./Mobile/LineItem";

class LineItem extends Component {
    constructor(props){
        super(props);
        this.removeLineItem = this.removeLineItem.bind(this);
        this.onLineItemChange = this.onLineItemChange.bind(this);
        this.onLineItemFocus = this.onLineItemFocus.bind(this);
        this.onCategoryLineSelected = this.onCategoryLineSelected.bind(this);

        this.defaultLineItemValues = {
            name: null,
            description: null,
            quantity: 1,
            price: 0,
            product_id: null,
        }
    }

    removeLineItem() {
        const { updateLineItems, lineItems, index} = this.props;
        const newLineItems = lineItems.filter((lineitem, itemIndex)=> itemIndex != index);
        updateLineItems([...newLineItems]);
    }

    onLineItemFocus(event){
        //if event.target === default value.?
        const changedAttribute = event.currentTarget.dataset.attribute;

        switch (changedAttribute) {
            case "description":
                if (event.target.innerHTML == this.defaultLineItemValues.description)
                    window.getSelection().selectAllChildren(event.target)
                break;
            case "name":
                if (event.target.innerHTML == this.defaultLineItemValues.name)
                    window.getSelection().selectAllChildren(event.target)
                break;
            case "quantity":
                    window.getSelection().selectAllChildren(event.target)
                break;
            case "price":
                    window.getSelection().selectAllChildren(event.target)
                break;
        }
    }

    onLineItemChange(event) {
        // debugger;
        console.log("Line item changed!");
        const { lineItems, index, updateLineItems } = this.props;
        const changedAttribute = event.currentTarget.dataset.attribute;
        const updatedLineItems = [...lineItems];
        let cleanedValue = event.target.innerHTML || event.target.value;

        switch (changedAttribute) {
            case "quantity":
                cleanedValue = parseInt(cleanedValue) ? parseInt(cleanedValue) : 1;
                break;
            case "price":
                cleanedValue = cleanedValue.replace('$', '').replace(',', '')
                cleanedValue = parseFloat(cleanedValue) ? parseFloat(cleanedValue) : 0.00; 
                break;
            case "description":
                cleanedValue = cleanedValue.replace(/&nbsp;/g, ' '); //clear out any nbsp that comes here. This is due to calling event.target.innerHTML, it is getting HTML elements from it. 
                break;
        }

        updatedLineItems[index][changedAttribute] = cleanedValue;
        updateLineItems([...updatedLineItems]);        
    }

    onCategoryLineSelected(event){
        const { lineItems, index, updateLineItems } = this.props;
        const updatedLineItems = [...lineItems];

        const productId = event.value;
        const productName = event.label;
        const description = event.data_description;
        const price = event.data_price

        updatedLineItems[index].product_id = productId;
        updatedLineItems[index].name = productName;

        if (isBlank(lineItems[index].description)){
            updatedLineItems[index].description = description;  
        }
        
        if (lineItems[index].price == this.defaultLineItemValues.price){
            updatedLineItems[index].price = price;
        }

        updateLineItems([...updatedLineItems]);   
    }

    render() {
        const {name, description, quantity, price, editable, index, productId} = this.props;
        return(
            <React.Fragment>

                <LineItemDesktop {...this.props} 
                    onLineItemChange = {this.onLineItemChange} 
                    onLineItemFocus = {this.onLineItemFocus} 
                    onCategoryLineSelected = {this.onCategoryLineSelected}
                    onRemoveItem = {this.removeLineItem} />
                
                <LineItemMobile {...this.props} 
                    onLineItemChange = {this.onLineItemChange} 
                    onLineItemFocus = {this.onLineItemFocus} 
                    onCategoryLineSelected = {this.onCategoryLineSelected}
                    onRemoveItem = {this.removeLineItem}
                    />

            </React.Fragment>
        );
    }
}

export default LineItem;

