import React, {Component} from "react";

import LineItem from "./LineItem";
import LineItemHeader from "./LineItemHeader"

class LineItems extends Component {
    constructor(props){
        super(props);
        this.addLineItem = this.addLineItem.bind(this);
    }

    addLineItem() {
        const { updateLineItems, lineItems } = this.props;
        const newLineItem = {
            name: null,
            product_id: null,
            description: null,
            quantity: 1,
            price: 0,
        }
        updateLineItems([...lineItems, newLineItem])
    }    

    render() {
        const { lineItems, editable, updateLineItems } = this.props;

        //Second sort for safety. This sort was originally here before the one on InvoiceDocument.jsx was added, but this one alone wasn't sufficient. 
        // This second sort might be redundant now, however we are leaving it in because it is currently working and we don't want to break it.
        const sortedByPositionLineItems = [...lineItems].sort((a, b) => { //sorts this by position, but appears to not match up with the index. CAREFUL. This part of the code is somewhat fragile. Test well before deploying changes.
            const posA = typeof a.position === "number" ? a.position : Infinity;
            const posB = typeof b.position === "number" ? b.position : Infinity;
            return posA - posB;
        });

        //Note: This is kind of hacky/bad because I don't really understand this code super well. How it is doing this based off the index and based off the position is confusing. 
        // In essence, this 'position' attribute modifies where it gets shown to the user, but it doesn't update the 'index' of the original array because the original array
        // is based off the server's position.

        return(
            <React.Fragment>
                <LineItemHeader editable={editable} updateLineItems={updateLineItems} addLineItem={this.addLineItem}/>
                {
                    sortedByPositionLineItems.map(
                        (lineItem, index) =>
                        <LineItem
                            key={`line_item_${index}`}
                            index={index}
                            editable={ editable }
                            lineItems={lineItems}
                            productId={lineItem.product_id}
                            updateLineItems={updateLineItems}
                            name={lineItem.name}
                            description={lineItem.description}
                            quantity={lineItem.quantity}
                            price={lineItem.price}
                            editLineItem={this.editLineItem}
                            productOptions={this.props.productOptions}
                        />
                    )
                }
            </React.Fragment>
        );
    }
}

export default LineItems;

